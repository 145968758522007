.App{
  height: 100vh;
  display: flex;

}
body{
  background-color: #020230;
}

.main{
  width: 85%;
  background: linear-gradient(to bottom, rgba(0,0,0,0), #020230), url('./images/bgf.jpg') center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: white;
}
.top{
  height: 40%;
  position: relative;
  
}
.timer{
  width: 50px;
  height: 50px;
  border: 3px solid white;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 400;
  bottom: 10px;
  left: 80px;
}
.bottom{
  height: 50%;
}

.trivia{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

}
.question{
  width: 80%;
  background: linear-gradient(#020230, black);
  border: 2px solid white;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
}
.answers{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.answer{
  width: 40%;
  padding: 10px;
  text-align: center;
  background: rgb(39, 2, 39);
  border: 2px solid white;
  font-size: 20px;
  font-weight: 300;
  border-radius: 15px;
  cursor: pointer;
}
.answer:hover, .answer.active{
  background: blue;
}
.answer.correct{
  animation: correct 3s ease forwards;
}
@keyframes correct{
  0%,22%,42%{
    background: blue;
  }
  20%,40%,60%{
    background: linear-gradient(#0e0124, #22074d);

  }
  62%,100%{
    background: green;
  }
}
.answer.wrong{
  animation: wrong 3s ease forwards;
}
@keyframes wrong{
  0%,22%,42%{
    background: blue;
  }
  20%,40%,60%{
    background: linear-gradient(#0e0124, #22074d);

  }
  62%,100%{
    background: red;
  }
}
.money{
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.moneyList{
  list-style: none;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: .3rem;
  font-size: 17px;
}
.listItem.active{
  background-color: blueviolet;
  padding: .3rem .6rem;
  font-weight: 600;
  border-radius: 5px;
  width: max-content;
}

.earnText{
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.start {
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.startInput {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
}

.startInput:focus {
  outline: none;
}

.startButton {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}
::-webkit-scrollbar{
  display: none;
}

@media screen and (max-width: 458px) {
  .moneyList{
    display: none;
  }
  .main{
    width: 100%;
  }
  .money {
    width: 0%;
  }
  .top{
    height: 30%;
    position: relative;
  }
  .answers{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem;
  }
 
  .answer:hover{
    background: rgb(39, 2, 39);
  }
  .timer{
    bottom: 10px;
    left: 30px;
    top: 5rem;
  }
  
}